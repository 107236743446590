/* eslint-disable prettier/prettier */
import { IntlShape } from 'react-intl';
import { Curve } from '../../../../../corelogic/domain/models/curve/curve.model';
import { TYPE_CURVE_PARAMETER } from '../type';
import { ParameterNameVariableMapper } from './parameterNameMonoMulti';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const XLSX = require('xlsx');

const checkIfArrayNumber = (
  columnData: any,
  rowNumber: number,
  intl: IntlShape,
  reject: (reason: Error) => void,
) => {
  return columnData.slice(1).map((row: number[] | string) => {
    const value = row[rowNumber];
    if (value !== undefined && isNaN(parseFloat(value.toString()))) {
      reject(new Error(intl.formatMessage({ id: 'errorMessage.numbersRequest' })));
    }
    if (typeof value === 'string') {
      return parseFloat(value);
    }
    return value;
  });
};

const checkVariableType = (
  variable_type: string,
  columnData: any[],
  intl: IntlShape,
  reject: (reason?: any) => void,
) => {
  if (variable_type === TYPE_CURVE_PARAMETER.MonoVariable && columnData[0].length !== 2) {
    reject(new Error(intl.formatMessage({ id: 'errorMessage.twoColumnsForMonovariable' })));
  }
  if (variable_type === TYPE_CURVE_PARAMETER.Bivariable && columnData[0].length !== 3) {
    reject(new Error(intl.formatMessage({ id: 'errorMessage.threeColumnsForBivariable' })));
  }
};

const assignValues = (
  variable_type: string,
  columnData: any[],
  intl: IntlShape,
  reject: (reason?: any) => void,
) => {
  let x1: number[] = [];
  let x2: number[] = [];
  let y: number[] = [];
  if (variable_type === TYPE_CURVE_PARAMETER.MonoVariable) {
    x1 = checkIfArrayNumber(columnData, 0, intl, reject);
    y = checkIfArrayNumber(columnData, 1, intl, reject);
  } else if (variable_type === TYPE_CURVE_PARAMETER.Bivariable) {
    x1 = checkIfArrayNumber(columnData, 0, intl, reject);
    x2 = checkIfArrayNumber(columnData, 1, intl, reject);
    y = checkIfArrayNumber(columnData, 2, intl, reject);
  }
  return { x1, x2, y };
};

export const readExcelFile = (
  file: File,
  intl: IntlShape,
  parameterName: string,
): Promise<{ x1: number[]; x2: number[]; y: number[] }> => {
  const fileReader = new FileReader();
  const variable_type =
    ParameterNameVariableMapper[parameterName as keyof typeof ParameterNameVariableMapper].variable;
  return new Promise((resolve, reject) => {
    fileReader.onload = (event: ProgressEvent<FileReader>) => {
      if (event.target !== null) {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const firstSheetName: string = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const columnData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        checkVariableType(variable_type, columnData, intl, reject);
        const { x1, x2, y } = assignValues(variable_type, columnData, intl, reject);
        if (
          x1.length > 0 &&
          y.length > 0 &&
          (x2.length > 0 || variable_type === TYPE_CURVE_PARAMETER.MonoVariable)
        ) {
          resolve({ x1, x2, y });
        } else {
          reject(new Error(intl.formatMessage({ id: 'errorMessage.invalidFormat' })));
        }
      }
    };
    fileReader.readAsBinaryString(file);
  });
};

export const generateCurveExcel = (curve: Curve, parameterName: string) => {
  const { x1, x2, y } = curve.data;
  const hasX2 = x2 !== undefined && x2.length > 0;
  const data: string[][] = x1.map((x1Value, index) => {
    const row = [x1Value.toString(), y[index].toPrecision(4)];
    if (hasX2) {
      row.splice(1, 0, x2[index].toString());
    }
    return row;
  });

  data.unshift(hasX2 ? ['x1', 'x2', 'y'] : ['x1', 'y']);

  const ws = XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
  XLSX.writeFile(wb, `${parameterName}_${curve.name}.xlsx`);
};
