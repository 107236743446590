import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../corelogic/redux/create-store';
import { setUpdateSiteParameter } from '../../../../../../corelogic/redux/site/site.slice';
import styles from './addAdmin.module.scss';
import { CustomButton, CustomTextField } from '../../../../../../util/functionUtil';

type Props = { isOpen: boolean; handleClose: () => void };

const AddAdmin: FC<Props> = ({ isOpen, handleClose }) => {
  const dispatch = useDispatch();
  const { site } = useSelector((state: RootState) => state.site);

  const [updateNameState, setUpdateNameState] = useState<string>('');
  const [updateMailSate, setUpdateMailSate] = useState<string>('');
  const [updateJobTitleState, setUpdateJobTitleState] = useState<string>('');

  function resetState() {
    setUpdateNameState('');
    setUpdateMailSate('');
    setUpdateJobTitleState('');
  }

  function updateSite() {
    const updatedAdmins = [...site.admins];
    updatedAdmins.unshift({
      name: updateNameState,
      mail: updateMailSate,
      jobTitle: updateJobTitleState,
    });

    dispatch(setUpdateSiteParameter({ siteId: site._id, updateBody: { admins: updatedAdmins } }));
    resetState();
    handleClose();
  }

  return (
    <Dialog
      className={styles.dialogContainer}
      autoFocus
      id="failure"
      onClose={handleClose}
      open={isOpen}
      PaperProps={{
        style: { borderRadius: 16, maxWidth: '90%', width: '50%' },
      }}
    >
      <DialogTitle>
        <div className={styles.titleAndButtonsPopin}>
          <div>
            <FormattedMessage id="admin.add" />
          </div>
          <div className={styles.buttonContainerTags}>
            <div className={styles.buttonTags}>
              <CustomButton
                variant="outlined"
                type="button"
                className="btn btn-secondary "
                onClick={handleClose}
                color="primary"
              >
                <FormattedMessage id="button.cancel" />
              </CustomButton>
            </div>
            <div className={styles.buttonTags}>
              <CustomButton
                type="button"
                variant="contained"
                className="btn btn-primary "
                onClick={updateSite}
                color="primary"
                disabled={
                  updateJobTitleState == '' || updateMailSate === '' || updateNameState === ''
                }
              >
                <FormattedMessage id="button.publish" />
              </CustomButton>
            </div>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={styles.mandatoryTextContainer}>
          <div>
            <FormattedMessage id="popin.field" />
          </div>
          <div style={{ color: 'red' }}> * </div>
          <div>
            <FormattedMessage id="popin.mandatoryField" />
          </div>
        </div>
        <div className={styles.input_container}>
          <div className={styles.title}>
            <div>
              <span style={{ color: 'red' }}> * </span> <FormattedMessage id="admin.name" />
            </div>
            <CustomTextField
              size="small"
              type="text"
              placeholder="John Doe"
              onChange={e => setUpdateNameState(e.target.value)}
            ></CustomTextField>
          </div>
          <div className={styles.title}>
            <div>
              <span style={{ color: 'red' }}> * </span> <FormattedMessage id="admin.jobTitle" />
            </div>
            <CustomTextField
              size="small"
              type="text"
              placeholder="Engineer"
              onChange={e => setUpdateJobTitleState(e.target.value)}
            ></CustomTextField>
          </div>
        </div>
        <div>
          <div className={styles.inputs}>
            <div className={styles.title} style={{ width: '100%' }}>
              <div>
                <span style={{ color: 'red' }}> * </span> <FormattedMessage id="admin.mail" />
              </div>
              <CustomTextField
                size="small"
                fullWidth
                type="email"
                placeholder="mail@totalenergies.com"
                onChange={e => setUpdateMailSate(e.target.value)}
              ></CustomTextField>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddAdmin;
